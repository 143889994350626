export {};


import {ComponentsUtils} from './app/utils/ComponentsUtils'
import Glide from '../../tools/node_modules/@glidejs/glide'
import Alpine from '../../tools/node_modules/alpinejs'

function initGlider() {

if (document.querySelector(".glide")) {
    var glide = new Glide('.glide', {
        type: 'carousel',
        startAt: 0,
        perView: 3,
        gap: 20,
        autoplay: false,
        breakpoints: {
            1024: {
                perView: 2
            },
            640: {
                perView: 1
            }
        },

    });
    glide.mount()
}

}

let initApp = () => {
    ComponentsUtils.addScrolledClassToNavbarOnScroll();
    // @ts-ignore
    window.Alpine = Alpine;
    Alpine.start();
}

initApp();

initGlider();

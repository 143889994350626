export class ComponentsUtils {

    static addScrolledClassToNavbarOnScroll() {
        const navbar = document.querySelector('header')
        if (!navbar) return;

        const updateScrolledState = () => {
            navbar.classList.toggle('scrolled', window.scrollY != 0);
        }

        window.addEventListener('scroll', (e) => {
            updateScrolledState();
        })

        updateScrolledState();
    }


}


export function getClosest( elem: HTMLElement, selector: string ) {

    // Variables
    let firstChar = selector.charAt(0);
    let supports = 'classList' in document.documentElement;
    let attribute, value;

    // If selector is a data attribute, split attribute from value
    if ( firstChar === '[' ) {
        selector = selector.substr( 1, selector.length - 2 );
        attribute = selector.split( '=' );

        if ( attribute.length > 1 ) {
            value = true;
            attribute[1] = attribute[1].replace( /"/g, '' ).replace( /'/g, '' );
        }
    }

    // Get closest match
    for ( ; elem && elem !== document.body && elem.nodeType === 1; elem = <HTMLElement>elem.parentNode ) {

        // If selector is a class
        if ( firstChar === '.' ) {
            if ( supports ) {
                if ( elem.classList.contains( selector.substr(1) ) ) {
                    return elem;
                }
            } else {
                if ( new RegExp('(^|\\s)' + selector.substr(1) + '(\\s|$)').test( elem.className ) ) {
                    return elem;
                }
            }
        }

        // If selector is an ID
        if ( firstChar === '#' ) {
            if ( elem.id === selector.substr(1) ) {
                return elem;
            }
        }

        // If selector is a data attribute
        if ( firstChar === '[' ) {
            if ( elem.hasAttribute( attribute[0] ) ) {
                if ( value ) {
                    if ( elem.getAttribute( attribute[0] ) === attribute[1] ) {
                        return elem;
                    }
                } else {
                    return elem;
                }
            }
        }

        // If selector is a tag
        if ( elem.tagName.toLowerCase() === selector ) {
            return elem;
        }

    }

    return null;

}
